var render = function render(){
  var _vm$company$settings, _vm$filteredSuppliers;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-pane",
    attrs: {
      "id": `tab-scraperruns`,
      "role": "tabpanel"
    }
  }, [_c('div', {
    staticClass: "px-3"
  }, [_c('StartScraperRun', {
    attrs: {
      "suppliers": _vm.filteredSuppliers,
      "list-accounts": _vm.listAccounts
    },
    on: {
      "onStart": _vm.onClickStartRun
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8",
    class: {
      'col-md-12': !_vm.$auth.isAdmin
    }
  }, [_c('h4', {
    staticClass: "mb-1"
  }, [_vm._v("Crawler Runs")]), _c('p', [_vm._v(" See details about each individual crawler run for accounts under this supplier. "), !_vm.$auth.isAdmin ? _c('span', [_vm._v("Where a crawler run has failed, you can see the error and also download a screenshot of the page of the portal the crawler failed on.")]) : _vm._e()])]), _vm.$auth.isAdmin ? _c('div', {
    staticClass: "col-md-4"
  }, [_c('h4', {
    staticClass: "mb-1"
  }, [_vm._v("Number Of Crawlers In Queue")]), _c('p', [_vm._v(_vm._s((_vm$company$settings = _vm.company.settings) === null || _vm$company$settings === void 0 ? void 0 : _vm$company$settings.scraperCounter))])]) : _vm._e()]), _c('div', [_c('div', {
    staticClass: "bg-light block block-rounded"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('FormGroup', {
    staticClass: "m-3",
    attrs: {
      "id": "suppliers",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "label": "Select Supplier",
      "placeholder": "All Suppliers",
      "options": _vm.filteredSuppliers.map(function (s) {
        return {
          label: s.name,
          value: s._id
        };
      })
    },
    on: {
      "input": _vm.loadScraperRuns
    },
    model: {
      value: _vm.filters.selectedSupplier,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "selectedSupplier", $$v);
      },
      expression: "filters.selectedSupplier"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('FormGroup', {
    staticClass: "m-3",
    attrs: {
      "id": `search-scraperrun`,
      "type": "select2",
      "config": {
        allowClear: true
      },
      "label": "Select Account",
      "placeholder": "All Accounts",
      "options": _vm.accountFilterOptions,
      "disabled": !_vm.filters.selectedSupplier
    },
    on: {
      "input": _vm.loadScraperRuns
    },
    model: {
      value: _vm.filters.accountId,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "accountId", $$v);
      },
      expression: "filters.accountId"
    }
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('FormGroup', {
    staticClass: "m-3",
    attrs: {
      "id": `filter-status`,
      "type": "select",
      "label": "Status",
      "options": [{
        label: 'All',
        value: ''
      }, {
        label: 'Completed',
        value: 'completed'
      }, {
        label: 'Failed',
        value: 'error'
      }, {
        label: 'Login Failure',
        value: 'login'
      }, {
        label: 'Data Error',
        value: 'data'
      }, {
        label: 'In Progress',
        value: 'running'
      }]
    },
    on: {
      "input": _vm.loadScraperRuns
    },
    model: {
      value: _vm.filters.status,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status", $$v);
      },
      expression: "filters.status"
    }
  })], 1)])])])], 1), _c('div', {
    staticClass: "px-3",
    attrs: {
      "id": `scraperrun-accordion`
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "nav nav-pills ml-3"
  }, [_c('li', {
    staticClass: "d-flex align-items-end mb-2"
  }, [_c('FormGroup', {
    staticClass: "mb-1",
    style: {
      width: '14rem'
    },
    attrs: {
      "id": "graph-date-range",
      "date-format": "DD MMM YYYY",
      "type": "dateRangePicker",
      "label": "Date Range",
      "date-range": _vm.customDateRanges
    },
    on: {
      "input": _vm.loadScraperRuns
    },
    model: {
      value: _vm.filters.dateRange,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "dateRange", $$v);
      },
      expression: "filters.dateRange"
    }
  }), _c('div', [_c('a', {
    staticClass: "nav-link text-capitalize ml-3",
    class: {
      active: _vm.autoRefresh,
      'bg-info': _vm.autoRefresh
    },
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.onAutoRefresh();
      }
    }
  }, [_vm._v(" Automatic Refresh "), _vm.autoRefresh ? _c('div', {
    staticClass: "spinner-border ml-2",
    staticStyle: {
      "width": "15px",
      "height": "15px"
    },
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]) : _vm._e(), !_vm.autoRefresh ? _c('i', {
    staticClass: "fas fa-sync-alt ml-2"
  }) : _vm._e()]), _c('div', {
    staticClass: "text-gray-dark font-w600 font-size-sm mt-1"
  })])], 1)]), _vm.scraperRuns.length > 0 ? _c('Pagination', {
    staticClass: "mb-3 ml-auto mr-3 mt-4",
    attrs: {
      "summary": true,
      "current-page": _vm.filters.page,
      "total-pages": Math.ceil(_vm.scraperRunsTotal / 10),
      "on-click-page": _vm.loadScraperRuns,
      "loading": _vm.scraperRunsLoadingAction.list
    }
  }) : _vm._e()], 1), _vm.scraperRunsLoadingAction.list ? _c('Spinner') : !_vm.scraperRuns.length ? _c('div', {
    staticClass: "alert alert-warning mt-3"
  }, [_vm._v("No crawler runs found with the selected filters.")]) : _c('div', _vm._l(_vm.scraperRuns, function (scraperRun) {
    var _scraperRun$logs;
    return _c('div', {
      key: scraperRun._id,
      staticClass: "block block-rounded mb-1"
    }, [_c('div', {
      staticClass: "block-header block-header-default d-block",
      attrs: {
        "id": `accordion-${scraperRun._id}-h`,
        "role": "tab"
      },
      on: {
        "click": function ($event) {
          return _vm.onClickAccordion(scraperRun);
        }
      }
    }, [_c('a', {
      staticClass: "font-w600",
      attrs: {
        "data-toggle": "collapse",
        "data-parent": `scraperrun-accordion`,
        "href": `#accordion-${scraperRun._id}-q`
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('span', {
      staticClass: "rounded mr-3 opacity-75",
      class: _vm.handleStatusClass(scraperRun, 'bg'),
      staticStyle: {
        "width": "5px"
      }
    }), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('small', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(_vm._f("date")(scraperRun.createdAt, 'Do MMM YYYY HH:mm:ss')) + " ")]), _vm._v(" " + _vm._s(_vm.handleTitleMessage(scraperRun)) + " "), scraperRun.account ? _c('div', [_c('small', {
      staticClass: "mr-2"
    }, [_c('i', {
      staticClass: "fa fa-meter fa-fw"
    }), _vm._v(" " + _vm._s(scraperRun.account.meterPointNumber || scraperRun.deviceId))]), _c('small', {
      staticClass: "mr-2"
    }, [_c('i', {
      staticClass: "fa fa-building fa-fw"
    }), scraperRun.account.asset ? _c('span', [_vm._v(_vm._s(scraperRun.account.asset.siteName) + " - " + _vm._s(scraperRun.account.asset.addressString))]) : _c('span', [_vm._v("NO ASSET")])]), _c('small', [_c('i', {
      staticClass: "fa fa-fingerprint fa-fw"
    }), _vm._v(" " + _vm._s(scraperRun._id))]), _c('small', [_c('i', {
      staticClass: "ml-1 fa fa-sm fa-lightbulb"
    }), _vm._v(" " + _vm._s(scraperRun.account.supplierRef))])]) : _vm._e(), _c('p', {
      staticClass: "mb-1"
    }, [((_scraperRun$logs = scraperRun.logs) === null || _scraperRun$logs === void 0 ? void 0 : _scraperRun$logs.length) > 0 ? _c('span', {
      staticClass: "text-right mr-2"
    }, [_c('span', {
      staticClass: "badge",
      class: _vm.handleStatusClass(scraperRun, 'badge')
    }, [_vm._v(" " + _vm._s(_vm.handleLabelName(scraperRun)))])]) : _vm._e(), _c('span', {
      staticClass: "badge badge-primary mr-2"
    }, [_vm._v(" " + _vm._s(_vm.getSupplierName(scraperRun.supplierId)) + " ")])])])])])])]), _c('div', {
      staticClass: "collapse",
      attrs: {
        "id": `accordion-${scraperRun._id}-q`,
        "role": "tabpanel",
        "data-parent": `#scraperrun-accordian`
      }
    }, [_c('ScraperLogs', {
      attrs: {
        "scraper-run": scraperRun,
        "s3-url": _vm.s3Urls[scraperRun._id]
      },
      on: {
        "update-s3-url": function (url, id) {
          return _vm.$set(_vm.s3Urls, id, url);
        }
      }
    })], 1)]);
  }), 0), _vm.scraperRuns.length > 0 ? _c('Pagination', {
    staticClass: "mb-5 mt-3",
    attrs: {
      "summary": true,
      "current-page": _vm.filters.page,
      "total-pages": Math.ceil(_vm.scraperRunsTotal / 10),
      "on-click-page": _vm.loadScraperRuns,
      "loading": _vm.scraperRunsLoadingAction.list
    }
  }) : _vm._e()], 1), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.startRun.modal,
      "title": "Start Crawler Run",
      "loading": _vm.startRun.loading,
      "prevent": ""
    },
    on: {
      "close": function ($event) {
        _vm.startRun.modal = false;
      },
      "submit": _vm.onStartRun
    }
  }, [_vm.startRun.supplierId ? _c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" Please confirm that you would like to start a crawler run for: "), _c('div', {
    staticClass: "mb-2 mt-2"
  }, [_c('strong', [_vm._v("Supplier:")]), _vm._v(" " + _vm._s((_vm$filteredSuppliers = _vm.filteredSuppliers.find(function (s) {
    return s._id === _vm.startRun.supplierId;
  })) === null || _vm$filteredSuppliers === void 0 ? void 0 : _vm$filteredSuppliers.name))]), _vm.startRun.accountId ? _c('div', [_c('div', {
    staticClass: "mb-2 mt-2"
  }, [_c('strong', [_vm._v("Account: ")]), _vm._v(_vm._s(_vm.scraperRunAccountOptions.find(function (a) {
    return a.value === _vm.startRun.accountId;
  }).label))])]) : _vm._e()]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }