var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.loading ? _c('div', {
    staticClass: "mr-3"
  }, [_c('i', {
    staticClass: "fa fa-spinner-third fa-lg fa-spin"
  })]) : _vm._e(), _vm.summary ? _c('div', {
    staticClass: "align-items-center font-w600 mr-3"
  }, [_vm._v("Page " + _vm._s(_vm.currentPage) + " of " + _vm._s(_vm.totalPages))]) : _vm._e(), _vm.totalPages > -1 ? _c('ul', {
    staticClass: "pagination mb-0"
  }, [_c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#",
      "aria-label": "First"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(1);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-double-left"
  }), _vm._m(0)])]), _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading || _vm.currentPage - 1 <= 0
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#",
      "aria-label": "Prev"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(_vm.currentPage - 1);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-left"
  }), _vm._m(1)])]), _vm.currentPage === _vm.totalPages && _vm.currentPage - 2 > 0 ? _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(_vm.currentPage - 2);
      }
    }
  }, [_vm._v(_vm._s(_vm.currentPage - 2))])]) : _vm._e(), _vm.currentPage - 1 > 0 ? _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(_vm.currentPage - 1);
      }
    }
  }, [_vm._v(_vm._s(_vm.currentPage - 1))])]) : _vm._e(), _c('li', {
    staticClass: "page-item active"
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.currentPage))])]), _vm.currentPage + 1 <= _vm.totalPages ? _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(_vm.currentPage + 1);
      }
    }
  }, [_vm._v(_vm._s(_vm.currentPage + 1))])]) : _vm._e(), _vm.currentPage === 1 && _vm.currentPage + 2 <= _vm.totalPages ? _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(_vm.currentPage + 2);
      }
    }
  }, [_vm._v(_vm._s(_vm.currentPage + 2))])]) : _vm._e(), _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading || _vm.currentPage + 1 > _vm.totalPages
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#",
      "aria-label": "Next"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(_vm.currentPage + 1);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  }), _vm._m(2)])]), _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.loading
    }
  }, [_c('a', {
    staticClass: "page-link",
    attrs: {
      "href": "#",
      "aria-label": "Last"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangePage(_vm.totalPages);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-double-right"
  }), _vm._m(3)])])]) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("First")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Prev")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Next")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Last")])]);

}]

export { render, staticRenderFns }