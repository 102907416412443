var render = function render(){
  var _vm$scraperRun$logs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded ml-6"
  }, [_c('div', {
    staticClass: "block-content tab-content"
  }, [((_vm$scraperRun$logs = _vm.scraperRun.logs) === null || _vm$scraperRun$logs === void 0 ? void 0 : _vm$scraperRun$logs.length) > 0 ? _c('table', {
    staticClass: "table table-borderless w-auto"
  }, [_c('tbody', [_vm._l(_vm.scraperRun.logs, function (log) {
    return _c('tr', {
      key: log._id
    }, [_c('td', {
      staticClass: "p-3 border-0 mr-3",
      class: {
        'pb-1': log.status === 'error'
      }
    }, [_c('i', {
      staticClass: "fa-lg",
      class: _vm.handleIcon(log.status),
      attrs: {
        "aria-hidden": "true"
      }
    })]), _c('td', {
      staticClass: "border-0 pl-3"
    }, [_c('small', {
      staticClass: "font-size-xs"
    }, [_vm._v(_vm._s(_vm._f("date")(log.date, 'HH:mm:ss')))]), _c('h6', {
      class: _vm.handleClass(log.status)
    }, [_vm._v(" " + _vm._s(log.message) + " ")])])]);
  }), _vm.s3Url ? _c('tr', [_c('td', {
    staticClass: "m-0 p-0 border-0 pl-5"
  }), _c('td', {
    staticClass: "border-0"
  }, [_c('img', {
    staticClass: "rounded img-fluid mx-auto d-block bg-primary border",
    staticStyle: {
      "width": "95%"
    },
    attrs: {
      "src": _vm.s3Url
    }
  })])]) : _vm._e()], 2)]) : _c('div', {
    staticClass: "block block-rounded bg-light text-center p-3"
  }, [_vm._v("No logs available.")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }